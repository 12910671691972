* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
}

.link {
    width: 100%;
    text-decoration: none;
}
